import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useApiMutation } from "../../hooks/useApiMutation"
import Avatar from "@material-ui/core/Avatar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { useForm } from "../../hooks/formHook"
import Container from "@material-ui/core/Container"
import Input from "../ui/Input"
import { Button } from "../../components/ui/Button"
import Modal from "../ui/Modal"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "var(--color-teal)",
  },
  message: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: "1rem",
  },
  center: {
    textAlign: "center",
    justifyContent: "center",
  },
}))

const PasswordReset = ({ showHeader = true }) => {
  const classes = useStyles()

  const [formState, inputHandler, checkboxHandler, setFormData] = useForm({
    newPassword: {
      value: "",
    },
  })

  const [
    resetPassword,
    { loading: resetLoading, error: resetError, data: resetData },
  ] = useApiMutation("account", "resetPassword")

  useEffect(() => {
    if (window) {
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get("token")

      if (!token) {
        navigate("/login")
      }
    }
  }, [])

  const resetSubmitHandler = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    if (window) {
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get("token")

      // set the Auth header token so we validate expiry with middleware
      const tokenExpiry = new Date()
      tokenExpiry.setTime(tokenExpiry.getTime() + 60 * 1000) // Set a local expiry of 1 minute from now
      localStorage.setItem(
        "userData",
        JSON.stringify({ token, exp: tokenExpiry.toISOString() })
      )
      resetPassword({
        variables: {
          input: { token, newPassword: formState.inputs.newPassword.value },
        },
      })
    }
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {showHeader && (
            <>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Password Reset
              </Typography>
            </>
          )}
          <form className={classes.form} onSubmit={resetSubmitHandler}>
            {!resetData ? (
              <Grid item xs={12}>
                <Input
                  required
                  fullWidth
                  label="New Password"
                  name="newPassword"
                  type="password"
                  inputHandler={inputHandler}
                  minLength={4}
                />
              </Grid>
            ) : null}
            <Grid container justify="center" className={classes.submit}>
              {resetData ? (
                <>
                  {/* <Grid item xs={12}> */}
                  <div className={classes.message}>
                    <p className="large">
                      Success! Your password has been reset.
                    </p>
                  </div>
                  {/* </Grid> */}
                  <Grid item xs={12} className={classes.center}>
                    <Link to="/login">Login</Link>
                  </Grid>
                </>
              ) : (
                <Button
                  type="primary"
                  submit={true}
                  icon="key"
                  id="1"
                  sectionId="pass_reset"
                  title={"Reset Password"}
                />
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.message}>
                  {resetError && (
                    <p className="error">
                      We could not reset your password at this time
                    </p>
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  )
}

export default PasswordReset
