import { useCallback, useReducer } from "react"

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.name]: { value: action.value },
        },
      }
    case "CHECKBOX_CHANGE":
      return {
        state,
        inputs: {
          ...state.inputs,
          [action.name]: { value: action.checked },
        },
      }
    case "FILE_CHANGE":
      return {
        state,
        inputs: {
          ...state.inputs,
          [action.name]: { value: action.value },
        },
      }
    case "SET_DATA":
      return {
        inputs: {
          ...state.inputs,
          [action.name]: { value: action.value },
        },
      }
    default:
      return state
  }
}

export const useForm = initialInputs => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: initialInputs,
  })

  const inputHandler = useCallback(e => {
    dispatch({
      type: "INPUT_CHANGE",
      name: e.target.name,
      value: e.target.value,
    })
  }, [])

  const checkboxHandler = useCallback(e => {
    dispatch({
      type: "CHECKBOX_CHANGE",
      name: e.target.name,
      checked: e.target.checked,
    })
  }, [])

  const uploadHandler = useCallback(e => {
    dispatch({
      type: "FILE_CHANGE",
      name: e.target.name,
      value: e.target.files[0],
    })
  }, [])

  const setFormData = useCallback((inputData, formValidity) => {
    let data = {}
    for (const key in inputData) {
      data = {
        ...data,
        [key]: inputData[key],
      }
    }
    dispatch({
      type: "SET_DATA",
      inputs: inputData,
    })
  }, [])

  return [formState, inputHandler, checkboxHandler, setFormData, uploadHandler]
}
