import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PasswordReset from "../components/auth/PasswordReset"

type DataProps = {}

const ResetPasswordPage: React.FC<PageProps<DataProps>> = ({}) => {
  return (
    <Layout hasHero={false}>
      <SEO title="Password Reset" />
      <PasswordReset />
    </Layout>
  )
}

export default ResetPasswordPage
